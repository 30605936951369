"use client"

import { PublicMainMenu } from "@cyna/web/app/[lang]/(public)/components/mainMenu/PublicMainMenu"
import { SuccessMessageProvider } from "@cyna/web/providers/SuccessMessageProvider"
import { ReactNode } from "react"

const PublicLayout = ({
  children,
}: Readonly<{
  children: ReactNode
}>) => (
  <SuccessMessageProvider>
    <PublicMainMenu />
    <main className="flex h-full grow flex-col overflow-auto">{children}</main>
  </SuccessMessageProvider>
)

export default PublicLayout
